import { Password } from "@mui/icons-material";
import ProviderWiseBusiness from "components/Accounts/MISReports/ProviderBusiness";
// https://185.193.19.156:8443/fintrack
// https://stage-javaapi.bridgeconnect-runs.com/fintrack
// http://185.193.19.156:8091/fintrack/filter?businessStatus=n&businessType=bl&businessMonthId=9
const mainBaseUrl = "https://stage-javaapi.bridgeconnect-runs.com/fintrack";
// const mainBaseUrl = "https://prod-javaapi.bridgeconnect-runs.com/fintrack";
// const mainBaseUrl = "https://185.193.19.156:8443/fintrack";
// const mainBaseUrl = "http://185.193.19.156:9091/fintrack";
// const mainBaseUrl = "http://localhost:8091/fintrack";
const AccountUrl = "https://prod-accounts.bridgeconnect-runs.com/Api/AccountsMaster";
const AccountsEntry = "https://prod-accounts.bridgeconnect-runs.com/Api/AccountsEntry";
const AccountsReports = "https://prod-accounts.bridgeconnect-runs.com/Api/AccountReports";
const URLs = {
  dsaWiseBusinessByDateRange: `${mainBaseUrl}/dsamonthlybusiness/date-range?`,
  dsaWiseBusinessEntriesByDateRange: `${mainBaseUrl}/dsabusinessentries{dsaId}/date-range?`,
  productWiseBusinesByDateRange: `${mainBaseUrl}/api/productwise/date-range?`,
  providerWiseBusinesByDateRange: `${mainBaseUrl}/api/providerwise/by-date-range?`,
  connectorWiseBusinesByDateRange: `${mainBaseUrl}/api/connectorwise/date-range?`,
  monthlyBusinesssByDateRange: `${mainBaseUrl}/api/loan-summaries/by-date-range?`,
  productWiseBusiness: `${mainBaseUrl}/productwisebusiness`,
  productWiseBusinessByProductId: `${mainBaseUrl}/loanSummary`,
  providerWiseBusinessEntriesURL: `${mainBaseUrl}/loanSummaryByFinanceId?finananceId=`,
  connectorWiseEntriesByDateRange: `${mainBaseUrl}/loanSummaryBySubDsa?subDsaId=`,
  logiApiUrl: `${mainBaseUrl}/login`,
  companyURl: `${mainBaseUrl}/companyDetails`,
  providerUrl: `${mainBaseUrl}/provider-wise-business`,
  monthEndReportUrl: `${mainBaseUrl}/LoanSummaries`,
  dashboardUrl: `${mainBaseUrl}/api/dashboard?date=`,
  businessMonthDatesUrl: `${mainBaseUrl}/businessMonthYear`,
  connectorUrl: `${mainBaseUrl}/connector-wise-business`,
  dashboardLoansUrl: `${mainBaseUrl}/statusAproval?currentStatus=D&businessType=BL`,
  companyConfigurationUrl: `${mainBaseUrl}/companyDetails`,
  employeeMasterGetUrl: `${mainBaseUrl}/employeeDetails`,
  dsaMasterGetUrl: `${mainBaseUrl}/dsaDetails`,
  roleGetUrl: `${mainBaseUrl}/roleDetails`,
  fileUpload: `${mainBaseUrl}/import-csv`,
  finanaceGetUrlByTypeCodeUrl: `${mainBaseUrl}/finance`,
  financeBusinessGetUrl: `${mainBaseUrl}/businessDetails`,
  financeUrl: `${mainBaseUrl}/financeDetails`,
  entriesUrlById: `${mainBaseUrl}/financeDetailsByBusinessId`,
  baseUrl: mainBaseUrl,
  employeeDetailsByRoleIdUrl: `${mainBaseUrl}/employeesByRoleId`,
  businessEntriesUrl: `${mainBaseUrl}/businessentries`,
  monthEndUrl: `${mainBaseUrl}/monthEndDetails`,
  monthlyBusinessUrl: `${mainBaseUrl}/monthlyBusiness`,
  financeGetAllUrl: `${mainBaseUrl}/financeDetails`,
  accountsUrl: `${AccountUrl}/GetCashBankAccounts`, // External URL remains unchanged
  appAccessURl: `${mainBaseUrl}/details`,
  accessControlApi: `${mainBaseUrl}/loginEmployeeDetails`,
  PasswordUpdate: `${mainBaseUrl}/update`,
  removeAccess: `${mainBaseUrl}/delete`,
  removeRemaining: `${mainBaseUrl}/delete`,
  ParentGroupUrl: `${AccountUrl}/getParentGroups`,
  AllGroupUrl: `${AccountUrl}/getAllGroups`,
  PostAccountGroupUrl: `${AccountUrl}/postAccountGroup`,
  GetAccountsUrl: `${AccountUrl}/GetAccounts`,
  PostAccountLedger: `${AccountUrl}/postAccountLedger`,
  GetCashBankAccounts: `${AccountUrl}/GetCashBankAccounts`,
  GetCompanies: `${AccountUrl}/GetCompanies`,
  GetPaymentAccounts: `${AccountUrl}/GetPaymentAccounts`,
  PostVoucherEntry: `${AccountUrl}/PostVoucherEntry`,
  GetJVItem: `${AccountsEntry}/GetJVItem`,
  PostVoucherEntry: `${AccountsEntry}/PostVoucherEntry`,
  GetVoucherEntryList: `${AccountsEntry}/GetVoucherEntryList`,
  GetLedgerAccounts: `${AccountUrl}/GetLedgerAccounts`,
  getLedger: `${AccountsReports}/getLedger`,
  AccountPayableAndrURL: `${mainBaseUrl}/api/account-payable/Andromeda`,
  AccountReceivableAndrURL: `${mainBaseUrl}/accountReceivable/Andromeda`,
  AccountPayableDsaURL: `${mainBaseUrl}/accountpayblesubDsa`,
  AccountReceivableDsaURL: `${mainBaseUrl}/accountReceivableDsa`,
  EmployeeProductivity: `${mainBaseUrl}/employeeProductivity`,
};

export default URLs;
